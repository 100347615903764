<template>
  <h1 class="title">Все торги</h1>

  <TradeList
      v-if="activate"
      :url="api_url"
      :filter-statuses="statuses"
      :user-confirmed="userConfirmed"
      :remaining-time="days"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import moment from "moment";
import TradeList from "~/components/page/TradeList.vue";
import cookie from "~/utils/cookie";
import { loadUserInfo, user } from "~/utils/user";
import {echo, echoEvents} from "~/utils/echo";

useSeoMeta({
  title: 'Главная',
  description: 'главная'
});

const activate = ref(false);
const api_url = ref('all');
const userConfirmed = ref(false);
const days = ref(30);

const statuses = ref({
  onlyMyBids: {
    checked: false,
    name: 'Только мои ставки',
  },
  inTheLead:{
    checked: false,
    name: 'Я лидирую',
  },
  needToRaiseRate: {
    checked: false,
    name: 'Нужно поднять ставку',
  },
  onlyFavorites: {
    checked: false,
    name: 'Только избранное',
  }
});

onMounted(async() => {
  echo().channel(`public-channel`).listen(".lot-update-status-event", (message) => {
    for (let [key, callback] of Object.entries(echoEvents['lot-update-status-event-anonymous'])){
      callback(message);
    }
  }).subscribe();

  echo().channel(`public-channel`).listen(".lot-extended-event", (message) => {
    for (let [key, callback] of Object.entries(echoEvents['lot-extended-event-anonymous'])){
      callback(message);
    }
  }).subscribe();

  await loadUserInfo();

  userConfirmed.value = user?.isConfirmed || false;
  activate.value = true;
  let now = moment();
  let next = moment(user?.deactivatedAt);
  days.value = next.diff(now ,'days');
  api_url.value = (!!cookie.authToken.value() && userConfirmed?.value) ? 'all/for-my' : 'all';
});
</script>

<style scoped>

</style>
